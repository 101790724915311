<template>
  <div>
    <b-overlay
      :show="overlayStatus"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
    ></b-overlay>
    <div v-if="providers.length === 0 && !overlayStatus">
      <Empty
        :no-button="false"
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Actualmente no hay proveedores, para ver los tickets de cada proveedor, agrega uno primero."
        button-text="Crear un proveedor"
        :button-func="goToCreateProvider"
      />
    </div>
    <!-- Proveedores -->
    <b-tabs pills v-model="tabIndex" @input="resetTimer()">
      <b-tab v-for="(provider, index) in providers" :key="provider.idCompany">
        <template #title>
          <feather-icon
            :icon="provider.idCompany !== -1 ? 'UserIcon' : 'AlignCenterIcon'"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold"> {{ provider.companyName }} </span>
          <b-spinner small v-if="tableOverlays[provider.idCompany]" class="ml-1" />
        </template>
        <b-card no-body>
          <b-card no-body class="fixed">
            <div class="m-2">
              <!-- Filtros -->
              <b-row>
                <!-- Búsqueda -->
                <b-col
                  sm="12"
                  md="4"
                >
                  <b-form-group
                    label="Filtrar"
                    label-for="filter-input"
                  >
                    <b-input-group id="filter-input">
                      <b-form-input
                        placeholder="Escribe aquí..."
                        v-model="generalFilters[provider.idCompany]"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="dark"
                          :disabled="!generalFilters[provider.idCompany]"
                          @click="generalFilters[provider.idCompany] = ''"
                        >
                          Borrar
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- Rango de fechas -->
                <b-col
                  sm="12"
                  md="4"
                >
                  <b-form-group
                    label="Seleccionar fecha"
                    label-for="filter-date"
                  >
                    <flat-pickr
                      id="filter-date"
                      class="form-control d-inline-block"
                      :config="{ mode: 'range', dateFormat: 'm-d-Y' }"
                      v-model="dateFilters[provider.idCompany]"
                    />
                  </b-form-group>
                </b-col>
                <!-- Estatus -->
                <b-col
                  sm="12"
                  md="4"
                >
                  <b-form-group
                    label="Elegir estatus"
                    label-for="filter-status"
                  >
                    <v-select
                      id="filter-status"
                      class="mb-2 item-selector-title"
                      placeholder="Estatus del ticket"
                      label="name"
                      :options="statusOptions"
                      :reduce="option => option.id"
                      v-model="statusFilters[provider.idCompany]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Por páginas -->
                <b-col cols="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <b-form-group
                    label="Registros por página"
                    label-for="per-page"
                  >
                    <v-select
                      id="per-page"
                      v-model="perPage[provider.idCompany]"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="d-flex align-items-center justify-content-end">
                  <b-button
                    variant="gradient-primary"
                    size="sm"
                    class="btn-icon"
                    @click="getLastTickets(provider.idCompany)"
                  >
                    <feather-icon icon="RefreshCwIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
          <!-- Tabla -->
          <b-overlay :show="tableOverlays[provider.idCompany]" rounded="sm">
            <b-table
              striped
              responsive
              :per-page="perPage[provider.idCompany]"
              :current-page="currentPages[provider.idCompany]"
              :items="providerItems[provider.idCompany]"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sortDesc="sortDesc"
              :filter="shouldFilter[provider.idCompany] ? [generalFilters[provider.idCompany], dateFilters[provider.idCompany], statusFilters[provider.idCompany]] : null"
              :filter-function="filterTable"
              empty-filtered-text="No hay tickets que cumplan con los filtros"
              @filtered="onFiltered"
              show-empty
              empty-text="No hay registros"
              class="position-relative"
            >
              <!-- ID -->
              <template #cell(shortOrderId)="data">
                <b>{{ data.value }}</b>
              </template>

              <!-- Fecha -->
              <template #cell(creationDate)="data">
                {{ new Date(data.value) | moment("DD/MM/YYYY - H:mm") }}
              </template>

              <!-- Total -->
              <template #cell(total)="data">
                <span>Total: ${{ formatNumber(data.item.total) }}</span>
              </template>

              <!-- Acción -->
              <template #cell(items)="row">
                <b-button variant="light" size="sm" @click="row.toggleDetails">
                  <feather-icon :icon=" row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'" class="mr-50" />
                  {{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }}
                </b-button>
              </template>

              <!-- Detalles -->
              <template #row-details="row">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td> Material </td>
                              <td> Neto </td>
                              <td> Precio </td>
                              <td> Subtotal </td>
                              <td v-if="$can('read', 'SnapshotsWeighing')"> Foto </td>
                            </tr>
                            <tr v-for="(item, index) in row.item.items" :key="index">
                              <td><b> {{ item.itemName }} </b></td>
                              <td>
                                <div>
                                  {{ item.quantityAdjustment }} {{ spinButtonNames.includes(item.itemName) ? "pza/s." : "kg" }}
                                  {{ item.adjustment > 0 ? "(-" + parseFloat(item.adjustment) + "kgs)" : ""}}
                                </div>
                              </td>
                              <td> ${{ item.price }} </td>
                              <td>
                                ${{ item.subTotalAdjustment }}
                              </td>
                                <div v-if="item.snapshot">
                                  <b-button variant="outline-dark" size="sm" @click="showModal(item.snapshot)">
                                    <feather-icon icon="ImageIcon" />
                                  </b-button>
                                </div>
                                <div v-if="item.videoTicket">
                                    <b-button variant="outline-dark" size="sm" @click="showVideoTicket(item.videoTicket)">
                                        <feather-icon icon="ImageIcon" />
                                    </b-button>
                                </div>
                            </tr>
                          </tbody>
                        </table>
                        <div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

              <!-- Estatus de Pago -->
              <template #cell(idPayment)="data">
                <b-badge pill :variant="paymentStatuses.get(data.value)[1]">
                  {{ paymentStatuses.get(data.value)[0] }}
                </b-badge>
              </template>
            </b-table>
          </b-overlay>
          <!-- Paginación -->
          <div class="mx-2 mb-2" style="margin-top: 2em">
            <b-row>
              <b-col lg="12" sm="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPages[provider.idCompany]"
                  :total-rows="totalRows[provider.idCompany]"
                  :per-page="perPage[provider.idCompany]"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-modal id="pictureModal" title="Evidencia de pesaje" ok-title="Cerrar" ok-only centered hide-header-close>
      <b-img
        :src="sourceImage"
        alt="Evidencia de pesaje"
        thumbnail
        fluid
        center
        width="500em"
        height="500em"
      />
    </b-modal>
    <b-modal id="videoTicketModal" title="Evidencia de ticket" ok-title="Cerrar" ok-only centered hide-header-close>
        <div>
            <b-embed type="video" aspect="4by3" controls>
                <source :src="videoTicket" type="video/mp4">
            </b-embed>
        </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.fixed {
  position: sticky;
  top: 5.5em;
  z-index: 20;
  margin-bottom: 0;
  border-bottom: 3px solid #7367f0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BPagination,
  BButton,
  BOverlay,
  BModal,
  BImg,
  BTabs,
  BTab,
  BBadge,
  BSpinner,
    BEmbed
} from 'bootstrap-vue';
import Empty from '../components/empty-page/Empty';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    vSelect,
    flatPickr,
    BPagination,
    BButton,
    BOverlay,
    BModal,
    BImg,
    BTabs,
    BTab,
    BBadge,
    BSpinner,
    Empty,
      BEmbed
  },
  data() {
    return {
      // Table
      tableColumns: [
        { key: 'shortOrderId', label: 'ID', sortable: true, thStyle: { width: '1em' } },
        { key: 'creationDate', label: 'Fecha de creación', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'items', label: 'Detalles', sortable: false },
        { key: 'idPayment', label: 'Estatus', sortable: true },
      ],
      pageOptions: [10, 25, 50],
      sortBy: 'creationDate',
      sortDesc: true,

      // Data
      providers: [],
      perPage: {},
      providerItems: {},
      currentPages: {},
      totalRows: {},
      tableOverlays: {},

      // Filters
      generalFilters: {},
      dateFilters: {},
      statusFilters: {},
      shouldFilter: {},
      statusOptions: [
        { id: 1, name: 'INACTIVO', },
        { id: 2, name: 'ACTIVO', },
        { id: 3, name: 'CONTADO', },
        { id: 4, name: 'CREDITO', },
        { id: 5, name: 'SIN ENVIAR', },
        { id: 6, name: 'EN CURSO', },
        { id: 7, name: 'ENTREGADO', },
        { id: 8, name: 'SIN COBRAR', },
        { id: 9, name: 'PARCIAL', },
        { id: 10, name: 'COBRADO', },
        { id: 11, name: 'AJUSTE', }
      ],

      // Props
      userData: JSON.parse(localStorage.getItem('userData')),
      overlayStatus: true,
      sourceImage: null,
      videoTicket: null,
      finishedProviders: 0,
      tabIndex: 0,
      spinButtonNames: ['Bateria', 'Cubeta'],
      paymentStatuses: new Map([
        [1, ['INACTIVO', 'dark']],
        [2, ['ACTIVO', 'light']],
        [3, ['CONTADO', 'success']],
        [4, ['CREDITO', 'warning']],
        [5, ['SIN ENVIAR', 'danger']],
        [6, ['EN CURSO', 'warning']],
        [7, ['ENTREGADO', 'success']],
        [8, ['SIN COBRAR', 'danger']],
        [9, ['PARCIAL', 'warning']],
        [10, ['COBRADO', 'success']],
        [11, ['AJUSTE', 'info']],
      ]),
    };
  },
  async created() {
    await this.getCompanies();
    this.refresh = setInterval(this.automaticRefresh, 15000);  // Cada 15 segundos

    // Quitar proveedores extra si no eres broker, y traer sus tickets
    if (this.$can('read', 'Calculator')) {
      this.providers = this.providers.filter((provider) => provider.idCompany == this.userData.idCompany);
      this.getPurchaseTickets(this.userData.idCompany);
    } else {
      // Llamar a todos los proveedores
      const promises = [];
      this.providers.forEach((provider) => {
        if (provider.companyName !== "General") {
          promises.push(this.getPurchaseTickets(provider.idCompany));
        }
      });
      await Promise.all(promises);

      // Setear datos en pestaña general
      this.getGeneralData();
    }
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.refresh);
    next();
  },
  methods: {
    getCompanies() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(this.$services + 'providers/get_providers')
          .then((res) => {
            this.$refreshToken(res.headers['x-access-token']);
            if (!res.data.error) {
              this.providers = [...res.data.data];

              // Agregar pestaña de general
              this.providers.unshift({ companyName: "General", idCompany: -1 });

              // Datos predeterminados
              this.providers.forEach((provider) => {
                // Configuración de la tabla
                this.$set(this.perPage, provider.idCompany, 10);
                this.$set(this.providerItems, provider.idCompany, []);
                this.$set(this.currentPages, provider.idCompany, 1);
                this.$set(this.totalRows, provider.idCompany, 1);
                this.$set(this.tableOverlays, provider.idCompany, true);

                // Filtros
                this.$set(this.generalFilters, provider.idCompany, "");
                this.$set(this.dateFilters, provider.idCompany, "");
                this.$set(this.statusFilters, provider.idCompany, "");
                this.$set(this.shouldFilter, provider.idCompany, false);
              });
            }
            resolve(res.data);
          })
          .catch((error) => {
            this.$verifyToken(error.response.data.data);
            reject(error);
          })
          .finally(() => {
            this.overlayStatus = false;
          });
      });
    },
    getPurchaseTickets(idCompany) {
      return new Promise((resolve, reject) => {
        this.$set(this.tableOverlays, idCompany, true);
        this.$axios
          .get(this.$services + 'orders/get_provider_purchase_orders', {
            params: {
              idCompany: idCompany,
              lastOrders: false,
            },
          })
          .then((res) => {
            this.$refreshToken(res.headers['x-access-token']);
            if (!res.data.error) {
              // Data
              this.$set(this.providerItems, idCompany, res.data.data);
              this.$set(this.totalRows, idCompany, res.data.data.length);
              resolve();
            }
          })
          .catch((error) => {
            this.$verifyToken(error.response.data.data);
            reject();
          })
          .finally(() => {
            this.$set(this.tableOverlays, idCompany, false);
          });
      });
    },
    getGeneralData() {
      // Agrupar los datos de todos los proveedores
      const data = [];
      this.providers.forEach((provider) => {
        if (provider.companyName !== "General") {
          data.push(...this.providerItems[provider.idCompany]);
        }
      });

      // Setear esos datos
      this.$set(this.providerItems, -1, data);
      this.$set(this.totalRows, -1, data.length);
      this.$set(this.tableOverlays, -1, false);
    },
    async getLastTickets(idCompany) {
      // Parar contador
      clearInterval(this.refresh);

      if (idCompany === -1) {  // General
        this.$set(this.tableOverlays, -1, true);

        const promises = [];
        this.providers.forEach((provider) => {
          if (provider.companyName !== "General") {
            promises.push(this.getPurchaseTickets(provider.idCompany));
          }
        });
        await Promise.all(promises);

        this.getGeneralData();
      } else {  // Un sólo proveedor
        this.getPurchaseTickets(idCompany);
      };

      // Reiniciar contador
      this.refresh = setInterval(this.automaticRefresh, 15000);  // Cada 15 segundos
    },
    automaticRefresh() {
      // Refrescar la pestaña actualmente activa de forma automática
      if (this.providers) {
        this.getLastTickets(this.providers[this.tabIndex].idCompany);
      }
    },
    resetTimer() {
      // Reiniciar el contador para el refresco automático
      clearInterval(this.refresh);
      this.refresh = setInterval(this.automaticRefresh, 15000);  // Cada 15 segundos
    },
    filterTable(row, filter) {
      // Conseguir filtros
      const [general, date, status] = filter;

      // Ignorar fecha o estatus en caso de que estén vacías
      const ignoreStatus = status ? false : true;
      const ignoreDate = date ? false : true;
      let start, finish, rowDate;

      // Separar fecha en inicio y final
      if (!ignoreDate) {
        if (date.length > 10) {
          start = date.split(" a ")[0];
          finish = date.split(" a ")[1];
        } else {
          start = date;
          finish = start;
        }

        // Adecuar las fechas
        start = this.$moment(start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        finish = this.$moment(finish).set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
        rowDate = this.$moment(row.creationDate);
      }

      // Checar fila por fila si se debe de filtrar
      if ((row.shortOrderId.toLowerCase().includes(general.toLowerCase()) ||
          row.items.some((item) => item.itemName.toLowerCase().includes(general.toLowerCase()))) &&
          (ignoreStatus || row.idPayment == status) &&
          (ignoreDate || ((rowDate).isSameOrAfter(start) && (rowDate).isSameOrBefore(finish)))
        ) {
        return true;
      } else {
        return false;
      }
    },
    onFiltered(filteredItems) {
      // Ajustar la paginación de las tablas al ser filtradas
      this.$set(this.totalRows, this.providers[this.tabIndex].idCompany, filteredItems.length);
      this.currentPage = 1
    },
    updateShouldFilter() {
      // Si alguno de sus filtros está activo, filtrar esta compañía
      for (const idCompany in this.generalFilters) {
        if (this.generalFilters[idCompany] || this.dateFilters[idCompany] || this.statusFilters[idCompany]) {
          this.shouldFilter[idCompany] = true;
        } else {
          this.shouldFilter[idCompany] = false;
        }
      }
    },
    showModal(snapshot) {
      this.sourceImage = snapshot;
      this.$bvModal.show('pictureModal');
    },
    showVideoTicket(videoTicket) {
      this.videoTicket = videoTicket;
      this.$bvModal.show('videoTicketModal');
    },
    goToCreateProvider() {
      this.$router.push({ name: 'add-provider' });
    },
    formatNumber(value) {
      const number = this.$BigNumber(value);
      return number.toFormat(0, this.$BigNumber.ROUND_DOWN);
    },
  },
  watch: {
    'generalFilters': {
      handler: 'updateShouldFilter',
      deep: true,
    },
    'dateFilters': {
      handler: 'updateShouldFilter',
      deep: true,
    },
    'statusFilters': {
      handler: 'updateShouldFilter',
      deep: true,
    },
  },
};
</script>
